import React from 'react';
import keyStethImage from '../icons/lookingAhead.jpg';

function AboutUsLookingAhead() {
    return (
        <div className="jumbotron-mv">
            <div className="youtube-player-mv">
                <img
                    width="560"
                    height="315"
                    src={keyStethImage}
                    alt="Minutemint AI Logo"
                    title="Minutemint AI"
                    style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
                />
            </div>

            <div className="heading">
                <strong>Minutemint.AI: Revolutionizing Business Meetings</strong>
            </div>

            <div className="content">
                <p>
                    In the era of digital transformation, effective meeting management is essential for organizational success. Introducing Minutemint AI, a cutting-edge solution by MadasHealth.ai designed to elevate meeting efficiency and collaboration across industries.
                </p>

                <p>
                    Minutemint AI harnesses advanced AI technology to optimize meeting processes, whether conducted in person or virtually. It empowers teams with real-time transcription, intelligent summarization, and automated task management, ensuring every meeting is productive and outcomes-driven.
                </p>

                <p>
                    Key Features of Minutemint AI:
                </p>
                <ul>
                    <li>Advanced Natural Language Processing (NLP): Customized models for meeting contexts ensure accurate transcription, action item identification, and decision tracking.</li>
                    <li>Real-Time Transcription: Seamlessly transcribes discussions across diverse meeting environments, preserving accurate records for enhanced collaboration.</li>
                    <li>Intelligent Summarization: Provides concise meeting summaries that highlight critical decisions and discussions, facilitating quick reviews and action planning.</li>
                    <li>Action Item Automation: Automatically assigns tasks and tracks action items, fostering accountability and improving follow-up efficiency.</li>
                    <li>Seamless Integration: Integrates seamlessly with existing digital tools and platforms, from scheduling software to project management systems.</li>
                    <li>Calendar and Task Management Integration: Enables easy scheduling of follow-up meetings, setting reminders, and updating task lists, optimizing workflow integration.</li>
                    <li>Sentiment Analysis: Analyzes meeting sentiment to gauge team dynamics and participant engagement, enhancing collaboration and communication.</li>
                    <li>Customization and Learning: Adapts and improves based on user feedback, tailoring outputs to meet specific organizational needs and preferences.</li>
                    <li>Privacy and Security: Utilizes robust data protection measures on Microsoft Azure servers, ensuring compliance with stringent digital security standards.</li>
                </ul>
            </div>
        </div>
    );
}

export default AboutUsLookingAhead;
