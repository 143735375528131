import React from 'react';
import voiceTranscriptionIcon from '../icons/voice-transcription-icon.png';
import dataAnalysisIcon from '../icons/data-analysis-icon.png';
import intelligentSummarizationIcon from '../icons/intelligent-summarization-icon.png';
import actionItemAutomationIcon from '../icons/action-item-automation-icon.png';
import integrationIcon from '../icons/integration-icon.png';

function InformationSection() {
    return (
        <div className="information-sections">
            <label className="question-working">How does Minutemint AI work?</label>
            <div className="content-answer">Minutemint AI harnesses advanced AI technologies to redefine meeting management across industries, ensuring efficiency, productivity, and seamless collaboration.</div>

            <div className="step-grid">
                <div className="step">
                    <div className="logo-block"><img className="gridlogo" src={voiceTranscriptionIcon} alt="Voice Transcription Icon"></img></div>
                    <label className="question-working-sub">Voice Transcription</label>
                    <div className="content-answer">Minutemint AI begins by accurately transcribing discussions from various meeting formats, including face-to-face, teleconferences, and video calls. It captures every detail to provide a comprehensive record of the meeting.</div>
                </div>

                <div className="step">
                    <div className="logo-block"><img className="gridlogo" src={dataAnalysisIcon} alt="Data Analysis Icon"></img></div>
                    <label className="question-working-sub">Data Analysis</label>
                    <div className="content-answer">Using advanced natural language processing (NLP), Minutemint AI analyzes meeting content to identify key themes, action items, and decision points. It transforms raw data into actionable insights.</div>
                </div>

                <div className="step">
                    <div className="logo-block"><img className="gridlogo" src={intelligentSummarizationIcon} alt="Intelligent Summarization Icon"></img></div>
                    <label className="question-working-sub">Intelligent Summarization</label>
                    <div className="content-answer">Minutemint AI provides smart, concise summaries of meetings, highlighting essential discussions and outcomes. It simplifies information for quick reference and decision-making.</div>
                </div>

                <div className="step">
                    <div className="logo-block"><img className="gridlogo" src={actionItemAutomationIcon} alt="Action Item Automation Icon"></img></div>
                    <label className="question-working-sub">Action Item Automation</label>
                    <div className="content-answer">Automatically identifies action items discussed during meetings and assigns tasks to participants. It ensures accountability and streamlines follow-up processes.</div>
                </div>

                <div className="step">
                    <div className="logo-block"><img className="gridlogo" src={integrationIcon} alt="Integration Icon"></img></div>
                    <label className="question-working-sub">Seamless Integration</label>
                    <div className="content-answer">Integrates seamlessly with existing digital ecosystems, including scheduling tools and project management software, enhancing workflow efficiency without disruptions.</div>
                </div>

            </div>
        </div>
    );
}

export default InformationSection;
