import React from 'react';
import keyStethImage from '../icons/Logo.jpg';

function AboutUsJumbo() {
    return (
        <div className="jumbotron-aboutUs">
            <div className="heading" style={{ textAlign: 'center' }}>
                <strong>Minutemint.AI: Transforming Meeting Management Across Every Industry</strong>
            </div>

            <div className="content">
                <strong>
                    In the modern business landscape, where meetings are the backbone of collaboration, MadasHealth.ai proudly presents Minutemint AI. This innovative solution is designed to revolutionize meeting management across various industries, bringing efficiency and intelligence to every discussion, whether face-to-face or virtual.
                </strong>
            </div>

            <div className="content">
                <strong>
                    <p>
                        Advanced AI for Every Meeting Scenario: Minutemint AI is a versatile tool adept for face-to-face meetings, teleconferences, and videoconferencing platforms like Zoom or MS Teams. It uses advanced AI technology to transform the way meetings are conducted, ensuring that no critical point or decision is overlooked, regardless of the meeting format.
                    </p>
                    <p>
                        Key Features of Minutemint AI:
                    </p>
                    <ul>
                        <li>Advanced Natural Language Processing (NLP): Tailored models for meeting contexts to accurately transcribe discussions, segment conversations, identify action items, and recognize decisions made during meetings.</li>
                        <li>Real-Time Transcription: Efficiently transcribes discussions in any meeting setup - in-person or virtual, providing an accurate textual record of conversations.</li>
                        <li>Intelligent Summarization: Offers smart, concise summaries of meetings, highlighting essential points, decisions, and discussions, perfect for quick reviews and action planning.</li>
                        <li>Action Item Automation: Automatically identifies and assigns tasks to participants, ensuring clear accountability and streamlined follow-up.</li>
                        <li>Seamless Integration: Integrates with existing digital ecosystems from scheduling tools to project management software without disruptions.</li>
                        <li>Integration with Calendar and Task Management Tools: Schedule follow-up meetings, set reminders for action items, and update project management software.</li>
                        <li>Sentiment Analysis: Analyzes meeting tone and sentiment to provide insights into team dynamics and participant engagement.</li>
                        <li>Customization and Learning: Learns from user feedback to customize outputs, making minutes more relevant and useful.</li>
                        <li>Multilingual Support: Offers transcription and summarization services in multiple languages, ideal for international teams (in development phase).</li>
                        <li>Privacy and Security: Utilizes Microsoft Azure GPT servers for secure data processing, adhering to Australian digital standards.</li>
                    </ul>
                </strong>
            </div>

            <div className="youtube-player">
                <img
                    width="560"
                    height="315"
                    src={keyStethImage}
                    alt="Minutemint AI Logo"
                    title="Minutemint AI"
                    style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
                />
            </div>
        </div>
    );
}

export default AboutUsJumbo;
