import React from 'react';

function Copyright() {
  return (
    <div className="copyright-container">
        <div className="copyright-text">Copyright © 2024 AI-Powered Healthcare Excellence - All Rights Reserved.</div>
        <div className="powered-by">Powered by&nbsp;<a className="link" href="https://gtechnologies.au/">GTechnologies Pty Ltd</a>, Sydney Australia</div>
    </div>
  );
}

export default Copyright;
