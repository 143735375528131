import React from 'react';

function PrivacyPolicyInfo() {
    return (
        <div className="information-sections">
            <label className="question-working">Privacy Policy</label>
            <div className="content-answer">At Minutemint AI, we are committed to protecting your privacy and ensuring the security of your information. This policy outlines our adherence to stringent data protection standards, including compliance with the Australian Privacy Principles (APPs) and the Privacy Act 1988 (Cth).</div>

            <div className="step-grid-priv-pol">
                <div className="step-priv-pol">
                    <label className="question-working-sub">Information Collection</label>
                    <div className="content-answer"><strong>Scope:</strong> Minutemint AI collects necessary data to enhance functionality, such as device details, usage patterns, IP addresses, and browser specifics. We do not collect or retain personally identifiable patient data, ensuring compliance with health data protection standards.</div>
                    <div className="content-answer"><strong>Compliance:</strong> Our data collection practices adhere to the highest standards, including the Health Privacy Principles outlined in relevant legislation, ensuring respect for health-related privacy.</div>
                    <div className="content-answer"></div>

                    <label className="question-working-sub">Information Use</label>
                    <div className="content-answer"><strong>Improvement:</strong> The information gathered helps us refine our services, conduct comprehensive usage analysis, and enhance user experience across our platform.</div>
                    <div className="content-answer"><strong>Data Integrity:</strong> We uphold the accuracy and relevance of collected information, aligning with guidelines from the Australian Digital Health Agency.</div>
                    <div className="content-answer"></div>

                    <label className="question-working-sub">Information Sharing</label>
                    <div className="content-answer"><strong>Commitment:</strong> Minutemint AI does not sell or share user data with third parties. Any potential disclosure of data will strictly adhere to Australian Privacy Principles and the Privacy Act 1988.</div>
                    <div className="content-answer"><strong>Legal Compliance:</strong> Our practices ensure compliance with national standards, safeguarding user privacy and confidentiality.</div>
                    <div className="content-answer"></div>

                    <label className="question-working-sub">Information Security</label>
                    <div className="content-answer"><strong>Protection:</strong> We prioritize the security of your data. Minutemint AI employs advanced cloud security solutions, such as Microsoft Azure, to ensure data integrity and confidentiality.</div>
                    <div className="content-answer"><strong>Standards Adherence:</strong> Our data protection strategies align with the Australian Government Information Security Manual and Standards Australia's best practices.</div>
                    <div className="content-answer"></div>

                    <label className="question-working-sub">Policy Updates</label>
                    <div className="content-answer"><strong>Modifications:</strong> While we may update this policy periodically, we are committed to transparent communication. Any changes will be promptly communicated to our users.</div>
                    <div className="content-answer"><strong>Thank you for trusting Minutemint AI. Your privacy is of utmost importance to us.</strong></div>
                </div>

            </div>
        </div>
    );
}

export default PrivacyPolicyInfo;

