import React from 'react';
import keyStethImage from '../icons/keySteth.jpg';  // Import the image based on the given directory structure

function Jumbotron() {
    return (
        <div className="jumbotron">
            <div className="heading"><strong>Minutemint.AI   </strong> Revolutionizing Meeting Management Across Industries, Developed by MadasHealth.ai
                Explore Our Website for More AI Solutions from MadasHealth.ai.

                <p style={{ fontSize: '30px' }}>As a flagship product of <strong><a href="https://madashealth.ai/">MadasHealth.ai</a></strong>, Minutemint AI sets new standards in meeting efficiency and collaboration. Powered by advanced AI technology, our platform enhances decision-making and productivity in every meeting format, from face-to-face to virtual environments.</p>
            </div>
        </div>
    );
}

export default Jumbotron;

