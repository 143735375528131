import React from 'react';
import keyStethImage from '../icons/team.webp';

function AboutUsTeam() {
    return (
        <div className="jumbotron-ourTeam">
            <div className="heading">
                <strong>Meet the Minutemint AI Team</strong>
            </div>
            <div className="content">
                <p>
                    At the heart of Minutemint AI is a dedicated team of diverse talents, united by a shared vision of transforming how meetings are managed across industries. Our team blends expertise in artificial intelligence, software development, and business strategy to deliver innovative solutions that redefine collaboration and productivity.
                </p>
                <p>
                    Key Figures Leading Minutemint AI:
                </p>
                <ul>
                    <li>
                        <strong>Dr. Eshwar Madas</strong> - Founder and CEO
                    </li>
                    <li>
                        <strong>Rajeshwar Reddy Konkisa</strong> - Chief Technology Officer (CTO)
                            </li>
                   
                </ul>
                <p>
                    Together, our team is committed to pushing the boundaries of AI-powered meeting management, ensuring that Minutemint AI remains at the forefront of innovation in every industry it serves.
                </p>
            </div>
            <div className="youtube-player">
                <img
                    width="560"
                    height="315"
                    src={keyStethImage}
                    alt="Minutemint AI Team"
                    title="Minutemint AI Team"
                    style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
                />
            </div>
        </div>
    );
}

export default AboutUsTeam;

