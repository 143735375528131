import React from 'react';
import keyStethImage from '../icons/Journey.jpg';

function AboutUsOurJourney() {
    return (
        <div className="jumbotron-aboutUs">
            <div className="heading">
                <strong>Minutemint.AI: Our Evolution</strong>
            </div>
            <div className="content">
                <p>
                    Since its inception, Minutemint AI has rapidly transformed the landscape of meeting management across industries. Launched by MadasHealth.ai, Minutemint AI has become synonymous with efficiency, intelligence, and seamless collaboration in business meetings.
                </p>
                <p>
                    Originally designed to meet the diverse needs of modern business environments, Minutemint AI integrates cutting-edge AI technology to streamline meeting processes. It ensures that every meeting, whether in-person or virtual, is optimized for productivity and decision-making.
                </p>
                <p>
                    Key Milestones of Minutemint AI:
                </p>
                <ul>
                    <li>From Concept to Reality: Introduced in 2023, Minutemint AI has revolutionized how organizations conduct meetings, offering advanced features tailored to enhance user experience and operational efficiency.</li>
                    <li>Adaptive AI Technology: Leveraging natural language processing (NLP) and real-time transcription capabilities, Minutemint AI accurately captures and summarizes discussions, empowering teams to focus on strategic outcomes.</li>
                    <li>Integration Excellence: Seamlessly integrates with leading digital platforms such as Zoom, MS Teams, and more, facilitating effortless collaboration and workflow continuity.</li>
                    <li>Global Impact: Trusted by businesses worldwide, Minutemint AI continues to set benchmarks in meeting management, driving measurable improvements in productivity and organizational performance.</li>
                    <li>Future Prospects: With ongoing advancements in AI and user-driven enhancements, Minutemint AI remains committed to redefining the future of business meetings, promising even greater efficiency and innovation.</li>
                </ul>
            </div>
            <div className="youtube-player">
                <img
                    width="560"
                    height="315"
                    src={keyStethImage}
                    alt="Minutemint AI Journey"
                    title="Minutemint AI Journey"
                    style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
                />
            </div>
        </div>
    );
}

export default AboutUsOurJourney;
