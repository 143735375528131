import React from 'react';

function Jumbotron() {
    return (
        <div className="jumbotron">
            <div className="heading"><strong>Minutemint AI:</strong> Transforming Meeting Management Across Every Industry, Developed by MadasHealth.ai
                Explore Our Website for More AI Solutions from MadasHealth.ai.

            </div>
           
        </div>
    );
}

export default Jumbotron;
