import React from 'react';

function ImpactsSection() {
    return (
        <div className="impacts-sections">
            <label className="question-working-impacts">Impacts and Benefits of Minutemint AI</label>

            <div className="step-grid-impact">
                <div className="step-impact">
                    <label className="question-working-sub">Enhanced Meeting Efficiency</label>
                    <div className="content-answer">Minutemint AI streamlines meeting processes, ensuring all discussions are accurately transcribed and documented in real-time, reducing errors and enhancing efficiency.</div>
                </div>

                <div className="step-impact">
                    <label className="question-working-sub">Reduced Operational Costs</label>
                    <div className="content-answer">By automating meeting management tasks such as transcription and action item assignment, Minutemint AI helps organizations cut down on administrative expenses and optimize resource allocation.</div>
                </div>

                <div className="step-impact">
                    <label className="question-working-sub">Improved Decision-Making</label>
                    <div className="content-answer">Provides intelligent summaries and actionable insights from meetings, empowering decision-makers to make informed choices promptly.</div>
                </div>

                <div className="step-impact">
                    <label className="question-working-sub">Enhanced Collaboration</label>
                    <div className="content-answer">Facilitates seamless collaboration across teams and departments, whether in-person or virtually, by integrating with popular videoconferencing platforms and project management tools.</div>
                </div>
                <div className="step-impact">
                    <label className="question-working-sub">Compliance and Security</label>
                    <div className="content-answer">Maintains high standards of data security and compliance with regulations such as GDPR and HIPAA, ensuring user data remains protected and confidential.</div>
                </div>
            </div>
        </div>
    );
}

export default ImpactsSection;
