import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// Importing components
import Navbar from './assets/components/Navbar.js';
import Jumbotron from './assets/components/Jumbotron.js';
import InformationSection from './assets/components/InfoSection.js';
import ImpactsSection from './assets/components/ImpactSection.js';
import SignupComponent from './assets/components/SignUpComponent.js';
import Footer from './assets/components/Footer.js';
import Copyright from './assets/components/CopyRights.js';
import ScrollToTop from './assets/components/scrollingFunction.js';

// Import all pages here
import AboutUsPage from './AboutUsPage.js';
import PrivacyPage from './PrivacyPolicyPage.js';
import InvestrorsPage from './InvestrorsPage.js';
// Import any other necessary pages here

// Importing CSS
import './assets/styles/header.css';
import './assets/styles/jumbotron.css';
import './assets/styles/information-sections.css';
import './assets/styles/impacts-sections.css';
import './assets/styles/signup-comp.css';
import './assets/styles/footer.css';

function App() {
    return (
        <Router>
            <ScrollToTop />
            <div className="App">
                <Routes>
                    <Route path="/privacy-policy" element={<PrivacyPage />} />
                    <Route path="/about-us" element={<AboutUsPage />} />
                    <Route path="/investors" element={<InvestrorsPage />} />
                    <Route path="/" element={<HomePage />} />
                </Routes>
            </div>
        </Router>
    );
}

function HomePage() {
    return (
        <>
            <Navbar />
            <Jumbotron />
            <InformationSection />
            <ImpactsSection />
            <SignupComponent />
            <Footer />
            <Copyright />
        </>
    );
}

export default App;
