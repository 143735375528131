import React from 'react';

function TermsAndConditionsSection() {
    return (
        <div className="impacts-sections">
            <label className="question-working-impacts">Terms and Conditions</label>

            <div className="step-grid-impact-tnc">
                <div className="step-impact">
                    <label className="question-working-sub">Service Use</label>
                    <div className="content-answer-tnc">Minutemint AI is provided on an "as is" basis. Users agree to responsibly use this service in compliance with all applicable laws and regulations.</div>

                    <label className="question-working-sub">Limitations</label>
                    <div className="content-answer-tnc">While Minutemint AI is a powerful tool, it is not intended to replace professional advice or delay medical treatment seeking.</div>

                    <label className="question-working-sub">Intellectual Property</label>
                    <div className="content-answer-tnc">All elements of the Minutemint AI service, including software, content, visual interfaces, graphics, design, compilation, and more, are owned by us and protected under relevant intellectual property laws.</div>

                    <label className="question-working-sub">Changes to Terms</label>
                    <div className="content-answer-tnc">Minutemint AI reserves the right to update these terms periodically. Users will be informed promptly of any changes.</div>

                    <label className="question-working-sub">Liability</label>
                    <div className="content-answer-tnc">To the maximum extent permitted by law, Minutemint AI disclaims liability for any loss or damage resulting from the use of the service.</div>
                </div>
            </div>
        </div>
    );
}

export default TermsAndConditionsSection;
